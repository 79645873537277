
.paginationWrapper .selected a {
  background-color: var(--dark-blue-01);
  border-radius: 50%;
}
.paginationWrapper .disabled{
  opacity: 0.6;
}
.paginationWrapper .previous, .paginationWrapper .next{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
}
.paginationWrapper .previous a,  .paginationWrapper .next a{
  display: block;
  background-image: url("../assets/images/paginationArrowLeft.svg");
  height: 16px;
  width: 16px;
}

.paginationWrapper .next a{
  background-image: url("../assets/images/paginationArrowRight.svg");
}

@media (max-width: 480px) {
  .paginationWrapper .previous, .paginationWrapper .next{
    width: 30px;
    height: 30px;
  }
}