@import "./colors.css";
@import "./common.css";
@import "./material.css";
@import "./pagination.css";
@import "./web3Modal.css";
@import "./title.css";

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
input,
button {
  font-family: "Poppins", sans-serif;
  padding: 0;
}
button {
  cursor: pointer;
}
input:focus,
button:focus {
  outline: none;
}
a {
  text-decoration: none;
}
* {
  box-sizing: border-box;
}
p {
  margin: 0;
}
ul {
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}
strong {
  font-weight: 600;
}

/* Heading */
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  font-weight: bold;
}
h1 {
  font-size: 60px;
  line-height: 60px;
  letter-spacing: -0.8px;
}
h2 {
  font-size: 48px;
  line-height: 48px;
  letter-spacing: -0.5px;
}
h3 {
  font-size: 40px;
  line-height: 40px;
  letter-spacing: -0.2px;
}
h4 {
  font-size: 32px;
  line-height: 44px;
  letter-spacing: -0.1px;
}
h5 {
  font-size: 24px;
  line-height: 24px;
  letter-spacing: -0.1px;
}

/* loading skeleton animation is CPU-expensive */
