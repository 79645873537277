.flex {
  display: flex;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-column {
  display: flex;
  flex-flow: column;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-align-center {
  display: flex;
  align-items: center;
}
.flex-justify-end {
  display: flex;
  justify-content: flex-end;
}
.flex-justify-start {
  display: flex;
  justify-content: flex-start;
}
.flex-align-end {
  display: flex;
  align-items: flex-end;
}
.flex-align-start {
  display: flex;
  align-items: flex-start;
}
.flex-justify-between {
  display: flex;
  justify-content: space-between;
}
.flex-justify-center {
  display: flex;
  justify-content: center;
}
.inline {
  display: inline;
}
.defaultBtn {
  display: flex;
  background: none;
  border: 0;
}
.w-auto {
  width: auto;
}
.container {
  width: 100%;
  max-width: 1320px;
  margin: 0 auto;
  min-height: 752px;
}
.error {
  color: var(--accent-red);
}
.text-dark-grey-08 {
  color: var(--dark-grey-08);
}
.truncatedText {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* media */
@media (max-width: 1400px) {
  .container {
    max-width: 100%;
    padding: 0 24px;
  }
}
@media (max-width: 1024px) {
  .container {
    min-height: inherit;
  }
}
@media (max-width: 670px) {
  .container {
    padding: 0 15px 120px;
  }
}
