/* web3-modal */
.web3modal-modal-lightbox{
  z-index: 11!important;
}
.web3modal-modal-card .web3modal-provider-name{
    margin-top: 10px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #415168;
  }
  .web3modal-modal-card .web3modal-provider-wrapper{
    padding: 0;
    border: 1px solid #F5F5FA;
  }
  .web3modal-modal-card .web3modal-provider-container{
    padding: 31px;
    border-radius: 0;
  }
  .web3modal-modal-card .web3modal-provider-description{
    margin: 0;
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #8D97A4;
  }