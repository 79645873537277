.btn {
  border: 0;
  border-radius: 40px;
  background: var(--primary-blue);
  color: #ffffff;
  font-weight: 600;
  border: 2px solid var(--primary-blue);
}
.mainAction {
  width: 120px;
}

.small {
  padding: 4px 14px;
  font-size: 12px;
  line-height: 16px;
  min-width: 86px;
}
.medium {
  padding: 6px 22px;
  font-size: 16px;
  line-height: 20px;
}
.large {
  padding: 10px 29px;
  font-size: 20px;
  line-height: 24px;
}

/* look */
.ghost {
  color: var(--dark-grey);
  border: 2px solid var(--dark-blue-03);
  background: transparent;
  font-weight: 500;
}
.textOnly {
  font-weight: 500;
  color: var(--dark-grey);
  border: 0;
  background: transparent;
}

/* state */
.btn:disabled {
  color: #ffffff;
  background: var(--primary-blue-04);
  border-color: transparent;
  cursor: inherit;
}
.ghost:disabled {
  background: transparent;
  border-color: var(--dark-grey-02);
  color: var(--dark-grey-06);
}
.btn:hover {
  border-color: #2e9bff;
  background: #2e9bff;
}
.ghost:hover {
  background: var(--dark-blue-005);
  border-color: var(--dark-blue-03);
}
.btn:disabled:hover {
  color: #ffffff;
  background: var(--primary-blue-04);
  border-color: transparent;
}
.ghost:disabled:hover {
  background: transparent;
  border-color: var(--dark-grey-02);
  color: var(--dark-grey-06);
}

.textOnly:hover {
  color: var(--dark-grey);
  border: 0;
  background: transparent;
}
.button:active {
  background: #0067c7;
}
