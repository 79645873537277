.modalBodyMainText {
  margin-bottom: 15px;
}
.modalBodyMainText svg {
  margin-right: 15px;
}
.errMsg {
  color: var(--accent-red);
}
.closeBtn {
  display: block;
  margin-left: auto;
  margin-top: 8px;
}
