.MuiSlider-root .MuiSlider-thumb::after {
  display: none;
}

/* disabled */
.MuiSlider-root.Mui-disabled .MuiSlider-rail {
  background: #d9dce1;
}
.MuiSlider-root.Mui-disabled .MuiSlider-track {
  background: #c6cbd2;
}
.MuiSlider-root .MuiSlider-thumb.Mui-disabled {
  width: 12px;
  height: 12px;
  border: 2px solid #c6cbd2;
}

/* .MuiPickersDatePickerRoot-toolbar */
.MuiDialog-container .MuiPickersToolbar-toolbar,
.MuiPickersBasePicker-container .MuiPickersDay-daySelected {
  background-color: #0081f9;
}
.MuiDialogActions-root .MuiButton-label {
  color: #0081f9;
  text-transform: capitalize;
  font-family: Poppins;
}
.MuiDialogActions-root .MuiButton-textPrimary:hover {
  background-color: transparent;
  font-family: Poppins;
}
.MuiPaper-root .MuiButton-root .MuiTypography-root,
.MuiPaper-root .MuiPickersToolbarButton-toolbarBtn,
.MuiTypography-root.MuiTypography-caption,
.MuiTypography-root.MuiTypography-body1 {
  font-family: Poppins;
}
