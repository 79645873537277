:root {
  --primary-blue: #0081f9;
  --primary-blue-01: #e6f3ff;
  --primary-blue-008: #ebf5ff;
  --primary-blue-02: #cce6fe;
  --primary-blue-015: #d9ecfe;
  --primary-blue-04: #99cdfc;
  --primary-blue-05: #80c1fc;
  --additional-blue: #3689fc;
  --additional-blue-2: #035cd8;
  --dark-blue-008: #ebedf6;
  --dark-blue-005: #f2f3f9;
  --dark-blue-01: #e6e9f4;
  --dark-blue-015: #d9ddee;
  --dark-blue-02: #cdd2e8;
  --dark-blue-03: #b4bcdd;
  --dark-blue-003: #f7f8fb;
  --dark-blue-05: #818fc6;

  --dark-grey: #415168;
  --dark-grey-005: #f5f6f7;
  --dark-grey-01: #e6e9f4;
  --dark-grey-02: #d9dce1;
  --dark-grey-03: #c6cbd2;
  --dark-grey-04: #b3b9c2;
  --dark-grey-06: #8d97a4;
  --dark-grey-08: #677485;
  --dark-grey-09: #f5f6fa;
  --dark-grey-10: #e8ebf5;

  --additional-red: #d7142b;
  --accent-red: #ff6a7c;
  --accent-red-015: #ffe9ec;

  --additional-green: #017464;
  --accent-green: #02d4b5;
  --accent-green-015: #d9f9f4;
  --accent-green-03: #b4f3e9;

  --accent-purple: #a170fd;
  --accent-pink: #ff97d0;
  --accent-pink-015: #fff0f8;
  --accent-turquoise: #02cae4;
  --accent-turquoise-015: #d9f7fb;

  --warning-orange: #ffc131;
  --warning-orange-01: #fff9eb;

  --card-shadow-default: 0px 1px 4px rgba(3, 30, 140, 0.18);
  --card-shadow-hover: 0px 2px 12px rgba(3, 30, 140, 0.18);
}
