@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 80px;
}
.circle {
  display: flex;
  align-items: center;
  justify-content: center;

  animation: rotate 0.75s linear infinite;
  -ms-animation: rotate 0.75s linear infinite;
}
.logo{
  position: absolute;
  top: 13px;
}