.typography {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
}

/* style name */
.caption{
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.25px;
}
.labels{
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.2px;
}
.bodyText{
  font-size: 16px;
  line-height: 24px;
}
.subTitle2{
  font-size: 20px;
  line-height: 30px;
}
.subTitle1{
  font-size: 24px;
  line-height: 36px;
}
.subTitle0{
  font-size: 28px;
  line-height: 42px;
}

/* type */
.normal{
  font-weight: normal;
}
.bold{
  font-weight: 600;
}
.medium{
  font-weight: 500;
}